body,
html {
  height: 100%;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
  overflow: hidden;
}
p {
  font-size: 20px;
}
